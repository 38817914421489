import $ from 'jquery';
import 'magnific-popup';

function init() {
  $('.js-people').magnificPopup({
    delegate: 'a',
    type: 'inline',
    closeBtnInside: true,
    autoFocusLast: true,
    midClick: true,
    preloader: false,
    focus: '.mfp-close'
  });
}

export default function initPopups(){
  $(document).ready( init )
}
