import $ from 'jquery'

const filterForm = $('.resources__filter-form')
const submitButton = filterForm.find('.button-submit')
const resourcesListWrapper = $('.resources__list-wrapper')
const resourcesList = $('.resources__list')
const pageUrl = $('.resources__wrapper').data('page-root-url')


const filterResources =  {
  parseResults: (posts) => {
    for (let post of posts) {
      let categories = post.categories.map(category =>{
        category = `<a href="${category.link}">${category.name}</a>`
        return category
      })
      resourcesList.append(`
      <div class="resource-item">
          <h3 class="resource-item__title">
            ${post.permalink ? '<a href="'+post.permalink+'">' : ''}
              ${post.title}
            ${post.permalink ? '</a>' : ''}
          </h3>
          <div class="resource-item__category-list-wrapper">
              <i class="resource-item__tag-icon"></i>
              <ul class="resource-item__category-list">
                ${categories.join('')}
              </ul>
          </div>
          <p>${post.summary}</p>
          ${post.file_url ? '<a href="'+post.file_url+'" target="_blank" class="button u-mt3">Download</a>' : ''}
      </div>
    `)
    }
  },
  postFilters: () => {
    resourcesList.empty()
    resourcesListWrapper.addClass('loading')
    if($('.button-reset-filters').length === 0) {
      filterForm.append('<button type="button" class="button button--text button-reset-filters">Reset</button>')
    }
    return $.ajax({
      type: 'POST',
      url: `${window.adminUrl}?${filterForm.serialize()}`,
      dataType: "json",
      data: {action: 'get_resource_entries'},
      success: function (response) {
        filterResources.parseResults(response)
        resourcesListWrapper.removeClass('loading')
        window.history.replaceState({}, null, `${pageUrl}?${filterForm.serialize()}`);
      }
    });
  },
}

export default function initResourceFilters() {
  filterForm.find('input').change(function(){
    filterResources.postFilters()
  })
  submitButton.click(function(){
    filterResources.postFilters()
  })
  $(document).on('click', '.button-reset-filters', function () {
    filterForm.find('input').prop('checked', false)
    filterResources.postFilters()
  })
}
